import React, { useEffect } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import PageBreadcrumb from "../components/Shared/PageBreadcrumb";

const PagePrivacy = () => {
  const pathItems = [
    //id must required
    { id: 1, name: "RR Healthcare", link: "/" },
    { id: 4, name: "Privacy Policy" },
  ];

  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
    return () => {
      window.removeEventListener("scroll", scrollNavigation, true);
    };
  }, []);

  const scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      document.getElementById("topnav").classList.add("nav-sticky");
    } else {
      document.getElementById("topnav").classList.remove("nav-sticky");
    }
  };

  return (
    <React.Fragment>
      {/* breadcrumb */}
      <PageBreadcrumb title="Privacy Policy" pathItems={pathItems} />
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="section">
        <Container>
          <Row className="justify-content-center">
            <Col lg={9}>
              <Card className="shadow border-0 rounded">
                <CardBody>
                  <p className="text-muted aboutus-text">
                    Welcome to our website. We understand and know how our
                    visitors value their privacy and we have created this
                    privacy policy so you can understand our policies and
                    procedures, what personally identifiable information you
                    must provide when visiting the website, and how we use such
                    information. This privacy policy applies only to
                    rr-healthcare.com and not to any other website or service.
                    If you disagree with this privacy policy, please do not use
                    the website.
                  </p>
                  <p className="text-muted aboutus-text">
                    We will collect personal information by lawful and fair
                    means and, where appropriate, with the knowledge or consent
                    of the individual concerned. Personal data should be
                    relevant to the purposes for which it is to be used, and, to
                    the extent necessary for those purposes, should be accurate,
                    complete, and up-to-date. We will protect personal
                    information by reasonable security safeguards against loss
                    or theft, as well as unauthorized access, disclosure,
                    copying, use or modification.
                  </p>
                  <h4>Personally Identifiable Information </h4>
                  <p className="text-muted aboutus-text">
                    Chinese & Southeast Asian Products FZCO (the Company) and
                    its affiliates may collect and store your personally
                    identifiable information (PII) if you voluntarily provide
                    such information to the Company. PII may include name, email
                    address, physical address, contact numbers and other
                    information that specifically identifies you. The Company
                    will only use PII as described herein. The Company may use
                    your PII to contact you about our products and services or
                    those of other third parties from time to time. Except for
                    the parties described below, we do not share your PII with
                    third parties without your consent. We may share your PII
                    with companies associated with the Company, technical
                    consultants and other third parties involved in the
                    maintenance and development of our website. We also reserve
                    the right to provide and/or disclose any information as
                    required by Courts or administrative agencies and to the
                    extent necessary to investigate suspected fraud, harassment
                    or other violations of any law, rule or regulation, the
                    website rules and policies, or the rights of any third
                    parties or to investigate and any suspected conduct which
                    the Company deems inappropriate.
                  </p>
                  <h4>Links to other websites </h4>
                  <p className="text-muted aboutus-text">
                    The website may contain links to websites maintained by
                    third parties. The Company is not responsible for the
                    privacy policies of such third parties. Please read their
                    privacy policies carefully before providing any PII to them.
                  </p>
                  <h4>
                    Review and Update your Personally Identifiable Information{" "}
                  </h4>
                  <p className="text-muted aboutus-text">
                    In order to ensure the PII we maintain is accurate, the
                    Company gives users the option to change, modify or delete
                    all PII previously provided. If you wish to change any
                    information in our database, please contact us at
                    <a href="mailto:info@rr-healthcare.com">
                      {" "}
                      info@rr-healthcare.com
                    </a>
                    .
                  </p>
                  <h4>Information Security </h4>
                  <p className="text-muted aboutus-text">
                    We are committed to taking reasonable steps to ensure the
                    security of your PII. To prevent unauthorised access,
                    maintain data accuracy and ensure the appropriate use of
                    information, the Company has put into place physical,
                    electronic and managerial procedures to help safeguard and
                    secure the information collected from the Internet. However
                    due to the inherent nature of the Internet, we cannot
                    guarantee that communications and information stored on the
                    website, or our servers will be completely free from
                    unauthorised access and your use of the website specifically
                    demonstrates your knowledge and acceptance of such risk.
                  </p>
                  <p className="text-muted aboutus-text">
                    If you have any queries about this privacy policy, the
                    website or your dealings with this website, you can contact
                    us by email at{" "}
                    <a href="mailto:info@rr-healthcare.com">
                      info@rr-healthcare.com
                    </a>
                    .
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default PagePrivacy;

import { Card, CardBody, Col, Container, Row } from "reactstrap";
import img1 from "../assets/images/rrhealth/Nitrile.png"
import img2 from "../assets/images/rrhealth/FDA.png"
import img3 from "../assets/images/rrhealth/Engineer.png"
import img4 from "../assets/images/rrhealth/Pro.png"

export default function Features() {
    return (
        <section className="section mt-5">

            <Container>
                <Row className="justify-content-center">
                    <Col xs={12}>
                        <div className="section-title text-center mb-4 pb-2">
                            
                            <h4 className="title mt-3 mb-4">Product Features</h4>
                            
                        </div>
                    </Col>
                </Row>

                <Row className="justify-content-center">
                    <Col lg={3} md={4} sm={6} className="mt-4 pt-2">
                        <Card className="border-0 text-center ">
                            <div className="icons text-primary text-center mx-auto">
                                <img src={img1} alt="sa" />
                            </div>

                            <CardBody className="p-0 mt-4">
                                <h5>NBR – Nitrile</h5>
                                <p className="text-muted mb-0">
                                    100% Powder & Latex Free<br/>
                                    for prevention of allergic reactions
                                </p>
                               
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg={3} md={4} sm={6} className="mt-4 pt-2">
                        <Card className="border-0 text-center ">
                            <div className="icons text-primary text-center mx-auto">
                                 <img src={img2} alt="sa" />
                            </div>

                            <CardBody className="p-0 mt-4">
                                <h5>CE & FDA Certified</h5>
                                <p className="text-muted mb-0">
                                    Fully certified for Medical,<br/>
                                    Industrial and Food-Processing Use
                                </p>
                               
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg={3} md={4} sm={6} className="mt-4 pt-2">
                        <Card className="border-0 text-center ">
                            <div className="icons text-primary text-center mx-auto">
                               <img src={img3} alt="sa" />
                            </div>

                            <CardBody className="p-0 mt-4">
                                <h5>Engineered for You</h5>
                                <p className="text-muted mb-0">
                                    3 different weights of product<br/> based on your requirements
                                </p>
                                
                            </CardBody>
                        </Card>
                    </Col>

                   <Col lg={3} md={4} sm={6} className="mt-4 pt-2">
                        <Card className="border-0 text-center features ">
                            <div className="icons text-primary text-center mx-auto">
                               <img src={img4} alt="sa" />
                            </div>

                             <CardBody className="p-0 mt-4">
                                <h5>Pro-Manus Guarantee</h5>
                                <p className="text-muted mb-0">
                                    Guaranteed quality at low prices<br/> direct from the factory via our<br/> e-commerce model
                                </p>
                                
                            </CardBody> 
                        </Card>
                    </Col>
                </Row>
            </Container>

        </section>
    );
}
import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import PageHeader from "../components/Shared/pageHeader";
import SectionTitle from "../components/Shared/SectionTitle";
import KeyFeatureBox from "../components/Shared/KeyFeatureBox1";
import about from "../assets/images/rrhealth/work/single.jpg";
import aboutus1 from "../assets/images/rrhealth/work/aboutus1.jpg";
import aboutus2 from "../assets/images/rrhealth/work/aboutus2.jpg";
import aboutus3 from "../assets/images/rrhealth/work/aboutus3.jpg";
import rrhealth4 from "../assets/images/rrhealth/work/rrhealth8.jpg";

class PageAboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathItems: [
        //id must required
        { id: 1, name: "RR Healthcare", link: "/" },
        { id: 3, name: "About Us" },
      ],

      keyfeatures: [
        {
          icon: "activity",
          title: "Healthcare & PPE",
          subtitle: "Professionals",
        },
        {
          icon: "award",
          title: "Only Certified Products",
          subtitle: "Guaranteed",
        },
        { icon: "anchor", title: ">20 years", subtitle: "Trusted" },
        {
          icon: "truck",
          title: "E-Commerce",
          subtitle: "Direct low-cost platform",
        },
        {
          icon: "compass",
          title: "Global Sourcing",
          subtitle: "Best-in-class manufacturing facilities",
        },
        {
          icon: "cloud-lightning",
          title: "Responsive & Focused",
          subtitle: "Customer Service",
        },
      ],
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <React.Fragment>
        <PageHeader title="Our Story" pathItems={this.state.pathItems} />

        <section className="section aboutus-page aboutus-text">
          <Container>
            <Row className="align-items-center mt-5">
              <h4>1990s</h4>
              <Col lg={7} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="clearfix">
                  <img
                    src={aboutus2}
                    className="rounded img-fluid mx-auto d-block"
                    alt=""
                  />
                </div>
              </Col>
              <Col lg={5} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="clearfix top-image-small">
                  <img
                    src={aboutus1}
                    className="rounded img-fluid mx-auto d-block mt-3"
                    alt=""
                  />
                </div>
                <div className="text-muted  mt-5">
                  <p>
                    The origins of the Business in PPE go back to 1992 in
                    England and the development of performance fabrics for
                    sports and workwear. Ranging from stretch micro-fibre knit
                    fabrics with wicking and anti-microbial properties to carbon
                    lattice non-wovens for bullet and stab-proof vests.
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="align-items-center mt-5 mb-3">
              <Col lg={7} md={8} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="section-title ms-lg-4">
                  {/* <h4 className="title mb-5">Our Story</h4> */}
                  <div className="text-muted">
                    <h4>2000s</h4>
                    <p>
                      The Business transformed from UK product development and
                      manufacturing to global development of niche multi-purpose
                      protective products in best-in-class facilities across
                      Asia. RR became renowned for research and development to
                      perfect volume manufacture in Asia of intelligent
                      performance-oriented knit solutions. From water-proof
                      knitwear to far-infrared thermal fabrics to performance
                      work-and-athleisurewear.
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg={5} md={4} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="clearfix">
                  <img
                    src={aboutus3}
                    className="rounded img-fluid mx-auto d-block"
                    alt=""
                  />
                </div>
              </Col>
              {/* <Col lg={6} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="clearfix">
                  <img
                    src={aboutus1}
                    className="rounded img-fluid mx-auto d-block"
                    alt=""
                  />
                </div>
              </Col> */}
            </Row>
            <Row className="align-items-center ">
              <Col lg={5} md={5} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="clearfix">
                  <img
                    src={about}
                    className="rounded img-fluid mx-auto d-block"
                    alt=""
                  />
                </div>
              </Col>
              <Col lg={7} md={7} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="section-title ms-lg-4">
                  <div className="mt-4 pt-2 text-muted">
                    <h4>2020s</h4>
                    <p>
                    The onset of the Covid-19 pandemic brought dramatic change to customer requirements.  Workwear took a back-seat as work-from-home became the new normal.  And demand surged across a range of PPE (Personal Protective Equipment) and especially masks and gloves.  Prices rocketed and desperate buyers all too often found themselves tricked into buying sub-standard product at vastly inflated prices and in worst case scenarios paid deposits only to receive nothing.  The splintering of global supply chains only exacerbated disfunction and price gouging.
                    </p>
                    <p>
                    As the pandemic unfolded in early 2020, we decided to step up and do our part to help protect the world.  We started a non-profit broking division to enable supply from Asia of high-quality, fully certified, fit-for-purpose medical PPE to desperate customers in UK and Europe.  We leveraged our extensive contacts in Asia and put genuine buyers and suppliers together on a pro-bono basis in order to make a difference.
                    </p>
                   
                  </div>
                </div>
              </Col>
              <Col lg={12} md={12} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="section-title ms-lg-4">
                  <div className="mt-4 pt-2 text-muted">
                    <p>
                        True to our DNA, we listened carefully to customers during this time.  It led to the development of our own brand of gloves: Pro-Manus.  High quality, disposable exam gloves manufactured to exacting specifications.  A brand where we could be sure of consistent quality and sure of value pricing.  And product with full US FDA and EU CE certification capable of giving you professional protection for your hands in a broad range of medical or industrial uses.
                    </p>
                    <p>
                        The Pro-Manus brand was launched in 2021 and has 3 different weights: SOFT TOUCH, SURE TOUCH & TOUGH TOUCH.  Each is carefully designed after extensive research and market feedback to cater to your specific needs.  And always at the highest quality with full certification.  In early 2022 we added Pro-Manus VINYL TOUCH and also offer our customers powder-free and with powder latex options.
                    </p>
                  </div>
              </div>
              </Col>
            </Row>
            <Row className="align-items-center pt-3">
              <Col lg={7} md={8} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="section-title ms-lg-4">
                  <div className="mt-4 pt-2 text-muted">
                    <h4>Our Ongoing Commitment</h4>
                    <p>
                      And RR-Healthcare is committed to bring you the Pro-Manus
                      brand at the lowest possible price through efficient
                      e-commerce channels. We believe that all countries should
                      have access to affordable essential PPE to guard against
                      biological, chemical and other environmental hazards.
                    </p>
                    <p>
                      Engineered for a vast range of end users, including:
                      medical professionals; care workers; cleaners; industrial
                      workers; office staff; food processors; beauty and spa
                      technicians; and for so many others. Whatever your
                      protective requirements are, we are here for you.
                    </p>
                    <p>Come be a Pro-Manus RR-Healthcare partner.</p>
                  </div>
                </div>
              </Col>
              <Col lg={5} md={4} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="clearfix">
                  <img
                    src={rrhealth4}
                    className="rounded img-fluid mx-auto d-block"
                    alt=""
                  />
                </div>
              </Col>
            </Row>
          </Container>

          <Container className="mt-100 mt-60">
            <SectionTitle
              title="Key Features"
              desc="Start working with RR-Healthcare and guarantee your supply of fully certified high quality nitrile exam gloves at great direct e-commerce prices"
            />

            <Row>
              <KeyFeatureBox keyfeatures={this.state.keyfeatures} />
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
export default PageAboutUs;

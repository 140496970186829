import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom"
import { Link } from "react-router-dom";
import { Container} from "reactstrap";
import logo from "../../assets/images/rrhealth/rr-healthcare_logo-250.png";
import list_to_tree from "../Tree/list_to_tree";
import { useStore } from "../../context/store";
import axios from "axios";
import './Layout.css';

const Topnav =(props)=> {
  const baseUrl =  "https://api.rr-healthcare.com/api/v1";
  const location = useLocation();
  const { productStore } = useStore()

  const [categoryLinks,setCategoryLinks] = useState([])
  const [productLinks, setProductLinks] = useState([])

  useEffect(() => {
    function getProducts(){
      axios.get(baseUrl + '/product/all')
        .then(response => {
          setProductLinks(response.data.data.map(en => ({ 
          id: en._id,
          title: en.name,
            link: en._id ,
            isOpenNestedSubMenu: false,
            nestedChild: [],
            parent: en.parent_id._id,
        })))
        })
        .catch(error => {
        console.log("error", error.response ? error.response : error)
        })
    }

    function getCategory(){
      axios.get(baseUrl + '/category/all')
        .then(response => {
          setCategoryLinks(response.data.data.map(en => ({ 
            id: en._id,
            title: en.name,
              link: "##" ,
              isOpenNestedSubMenu: false,
              nestedChild: [],
              parent: en.parent,
          })))
        })
        .catch(error => {
        console.log("error", error.response ? error.response : error)
        })
    }

    getProducts();
    getCategory();  
    
  }, [])

  const singleObject = [{id: "000000000000000000000000", title: "RR-Healthcare", parent: null, nestedChild: []}]
  const combinedLinks = [...singleObject, ...categoryLinks, ...productLinks];
  const Tree = list_to_tree(combinedLinks)

  const [isOpen,setIsOpen] = useState(false)
  const navLinks = [
    { id: 1, title: "Home", link: "/" },
    {
      id: 2,
      title: "Products",
      link: "products",
      isOpenSubMenu: false,
      child: Tree[0] && Tree[0].nestedChild
    },
    { id: 3, title: "About Us", link: "/aboutus" },
    { id: 4, title: "News", link: "/news" },
    { id: 5, title: "Contact Us", link: "/contactus" }
  ] 

  const toggleLine =()=> {
    setIsOpen(!isOpen);
  }

  const activateParentDropdown = (item) => {
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent1 = parent.parentElement;
      parent1.classList.add("active"); // li
      if (parent1) {
        const parent2 = parent1.parentElement;
        parent2.classList.add("active"); // li
        if (parent2) {
          const parent3 = parent2.parentElement;
          parent3.classList.add("active"); // li
          if (parent3) {
            const parent4 = parent3.parentElement;
            parent4.classList.add("active"); // li
          }
        }
      }
    }
  };

  const toggleActiveLink = (loc) => {
    if(location.pathname === loc){
      return "active"
    }else{
      return ""
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
    var matchingMenuItem = null;
    var ul = document.getElementById("top-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (window.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
    
  }, [])
 
  const scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80 && document.getElementById("topnav")) {
      if(document.getElementById("topnav")){
        document.getElementById("topnav").classList.add("nav-sticky");
      }
    } else {
      if(document.getElementById("topnav")){
        document.getElementById("topnav").classList.remove("nav-sticky");
      }
    }
  };

  const [categoryKey, setCategoryKey] = useState(null);
  const [productKey, setProductKey] = useState(null);

  const toggleCategoriesMenu = (index) => {
    if(index === categoryKey){
      return "submenu open"
    }else{
      return "submenu"
    }
  }

  const toggleProductsMenu = (index, childIndex) => {
    if(index === categoryKey && childIndex === productKey){
      return "submenu open"
    }else{
      return "submenu"
    }
  }
  
  return (
      <React.Fragment>
        {props.tagline ? props.tagline : null}
        <header id="topnav" className="defaultscroll sticky">
          <Container>
            <div>
              {props.hasDarkTopBar ? (
                <Link className="logo" to="/">
                  <img src={logo} height="60" className="logo-light-mode" alt="" />
                </Link>
              ) :
                <Link className="logo" to="/">
                  <span className="logo-light-mode">
                    <img src={logo} className="l-dark" height="60" alt="" />
                    <img src={logo} className="l-light" height="60" alt="" />
                  </span>
                </Link>
              }
            </div>
            {/* <NavbarButtons /> */}
            <div className="menu-extras">
              <div className="menu-item">
                <Link
                  to="#"
                  onClick={toggleLine}
                  className={
                    isOpen ? "navbar-toggle open" : "navbar-toggle"
                  }
                >
                  <div className="lines">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </Link>
              </div>
            </div>
            <div
              id="navigation"
              style={{ display: isOpen ? "block" : "none" }}
            >
              <ul className="navigation-menu" id="top-menu">
                {navLinks.map((navLink, key) =>
                  navLink.child ? (
                    <li className={`has-submenu ${toggleActiveLink(navLink.link)}`} key={key} >
                      {/* child item(menu Item) - Level 1 */}
                      <Link
                        to={navLink.link}
                        onClick={(event) => {
                          event.preventDefault();
                          setCategoryKey(key)
                        }}
                      >
                        {navLink.title}
                      </Link>
                      <span className="menu-arrow"></span>
                      {navLink.isMegaMenu ? (
                        // if menu is mega menu(2 columns grid)
                        <ul
                          className={
                            navLink.isOpenSubMenu
                              ? "submenu megamenu open"
                              : "submenu megamenu"
                          }
                        >
                          <li>
                            <ul>
                              {navLink.child.map((item, childKey) =>
                                item.id < 12 ? (
                                  <li key={childKey}>
                                    <Link to={item.link}>
                                      {item.title}
                                    </Link>
                                  </li>
                                ) : null
                              )}
                            </ul>
                          </li>
                        </ul>
                      ) : (
                        <ul
                          className={toggleCategoriesMenu(key)}                            
                        >
                        
                          {navLink.child.map((childArray, childKey) =>
                            childArray.nestedChild ? (
                              // sub menu item - Level 2
                              <li className="has-submenu" key={childKey}>
                                <Link
                                  to={childArray.link}
                                  onClick={(event) => {
                                    event.preventDefault();
                                    setProductKey(childKey)
                                  }}
                                >
                                  {childArray.title}{" "}
                                  {childArray.isNew ? (
                                    <span className="badge badge-pill badge-success">
                                      Added
                                    </span>
                                  ) : null}
                                </Link>
                                <span className="submenu-arrow"></span>
                                <ul
                                  className={toggleProductsMenu(key, childKey)}
                                >
                                  {childArray.nestedChild.map(
                                    (nestedChildArray, nestedKey) => (
                                      // nested sub menu item - Level 3
                                      <li key={nestedKey} onClick={() => {
                                        toggleLine()
                                        setCategoryKey(null)
                                        setCategoryKey(null)
                                      }}>
                                        <Link to={{pathname: "/product-detail/" + nestedChildArray.title, state: {params: nestedChildArray.link}}}
                                          onClick={() => productStore.setStateId(nestedChildArray.link)}
                                        >
                                          {nestedChildArray.title}{" "}
                                          {nestedChildArray.isNewPage ? (
                                            <span className="badge badge-danger rounded">
                                              NEW
                                            </span>
                                          ) : null}
                                          {nestedChildArray.isupdatePage ? (
                                            <span className="badge badge-pill badge-info">
                                              Updated
                                            </span>
                                          ) : null}
                                        </Link>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </li>
                            ) : (
                              <li key={childKey}>
                                <Link to={childArray.link}>
                                  {childArray.title}
                                </Link>
                              </li>
                            )
                          )}
                        </ul>
                      )}
                    </li>
                  ) : (
                    <li className={toggleActiveLink(navLink.link)} key={key} onClick={toggleLine}>
                      <Link to={navLink.link}>{navLink.title}</Link>
                    </li>
                  )
                )}
              </ul>
            </div>
          </Container>
        </header>
      </React.Fragment>
  );
  
}

export default observer(Topnav);

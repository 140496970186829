import React, { useEffect } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import PageBreadcrumb from "../components/Shared/PageBreadcrumb";

const PageConditions = () => {
  const pathItems = [
    //id must required
    { id: 1, name: "RR Healthcare", link: "/" },
    { id: 4, name: "CSEAP Terms & Conditions of Sale" },
  ];

  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
    return () => {
      window.removeEventListener("scroll", scrollNavigation, true);
    };
  }, []);

  const scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      document.getElementById("topnav").classList.add("nav-sticky");
    } else {
      document.getElementById("topnav").classList.remove("nav-sticky");
    }
  };

  return (
    <React.Fragment>
      <PageBreadcrumb
        title="CSEAP Terms & Conditions of Sale"
        pathItems={pathItems}
      />
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="section">
        <Container>
          <Row className="justify-content-center">
            <Col lg={9}>
              <Card className="shadow border-0 rounded">
                <CardBody>
                  <div>
                    <h5>DEFINITIONS</h5>
                    <p className="text-muted aboutus-text">
                    <strong>1.</strong> The following expressions in these Conditions shall
                      mean respectively:- "the Company" Chinese and Southeast
                      Asian Products FZCO (registered in UAE with Trade License
                      number L 796) whose registered office is at 8th Floor,
                      Offices 4, One Central, DWTC, Dubai, UAE. "Goods"
                      materials, products and/or services contracted for and/or
                      supplied by the Company from time to time "the Purchaser"
                      the person, company or body buying or offering to purchase
                      Goods
                    </p>
                    <h5>APPLICABILITY</h5>
                    <p className="text-muted aboutus-text">
                      <strong>2.</strong> The following terms are the Company's standard terms
                      and conditions of sale. The Company contracts for the
                      supply of Goods only on these terms. The Purchaser accepts
                      that these terms shall govern relations between itself and
                      the Company to the exclusion of any other terms including
                      conditions, warranties and representations written or
                      oral, express or implied even if contained in any of the
                      Purchaser's documents which purport to provide that the
                      Purchaser's own terms shall prevail.{" "}
                    </p>
                    <h5>QUOTATIONS AND ORDERS </h5>
                    <p className="text-muted aboutus-text">
                      <strong>3.</strong> Quotations are tendered without engagement and are
                      subject to confirmation upon receipt of order. Quotations
                      remain valid for a period of 5 days.{" "}
                    </p>
                    <p className="text-muted aboutus-text">
                      <strong>4.</strong> All verbal orders must be confirmed in writing within 2
                      days of the original instructions unless such requirement
                      is waived by the Company in writing or by performance of
                      the order.{" "}
                    </p>
                    <p className="text-muted aboutus-text">
                      <strong>5.</strong> Contracts, once accepted, cannot be varied or cancelled
                      except with the written consent of both parties and then
                      only on terms which fully indemnify the Company against
                      any loss caused directly or indirectly by the variation or
                      cancellation. The Company's consent may only be given by a
                      director thereof.{" "}
                    </p>
                    <p className="text-muted aboutus-text">
                      <strong>6.</strong> Representations concerning the Goods made by the
                      Company's employees and agents are not valid unless
                      confirmed by the Company in writing. The Purchaser
                      acknowledges that it does not rely on and waives any claim
                      for breach of any such representations which are not so
                      confirmed.{" "}
                    </p>
                    <p className="text-muted aboutus-text">
                      <strong>7.</strong> Any typographical, clerical or other error or omission
                      in any sales literature, quotation, price list, acceptance
                      of offer, invoice or other document or information issued
                      by the Company shall be subject to correction without any
                      liability on the part of the Company.{" "}
                    </p>
                    <p className="text-muted aboutus-text">
                      <strong>8.</strong> The Company shall not be liable for any advice or
                      recommendation given by it or its employees or agents to
                      the Purchaser or its employees or agents as to the
                      storage, application or use of the Goods which is not
                      confirmed in writing by the Company. The same is followed
                      or acted upon entirely at the Purchaser's own risk.{" "}
                    </p>
                    <p className="text-muted aboutus-text">
                      <strong>9.</strong> The Company reserves the right to modify any of the
                      Goods without prior notice provided that such modification
                      shall not affect the form, fit, function or maintenance of
                      the relevant Goods.{" "}
                    </p>
                    <p className="text-muted aboutus-text">
                      <strong>10.</strong> Goods offered "ex stock" are subject to prior sales.{" "}
                    </p>
                    <h5>PRICES</h5>
                    <p className="text-muted aboutus-text">
                      <strong>11.</strong> All prices are ex UAE FZ warehouse unless otherwise
                      agreed and (unless expressly so stated) and inclusive of
                      UAE import duty, but exclude VAT or other sales tax which
                      the Purchaser shall be additionally liable to pay to the
                      Company.{" "}
                    </p>
                    <p className="text-muted aboutus-text">
                      <strong>12.</strong> The Company reserves the right to amend the price of a
                      contract between the date of acceptance of an order and
                      final delivery of Goods if the Company considers such a
                      course is necessary due to any factor beyond the control
                      of the Company (such as but without limitation any foreign
                      exchange fluctuation, currency regulation, alteration of
                      duties, global health restrictions, significant increase
                      in the cost of labour, materials, logistics or other
                      costs) or any change in delivery dates, qualities,
                      quantities or specifications of the Goods which is
                      requested by the Purchaser or any failure of the Purchaser
                      to give the Company adequate information or instructions.{" "}
                    </p>
                    <h5>DELIVERY</h5>
                    <p className="text-muted aboutus-text">
                      <strong>13.</strong> Delivery of the Goods shall be made by the Purchaser
                      collecting the Goods at the Company's premises at any time
                      after the Company has notified the Purchaser that the
                      Goods are ready for collection or, if some other place of
                      delivery is agreed by the Company, by the Company
                      delivering the Goods to that place.{" "}
                    </p>
                    <p className="text-muted aboutus-text">
                      <strong>14.</strong> Any times stated for delivery are estimates only and
                      time shall not be of the essence of the contract save
                      where the Company specifically so agrees in writing signed
                      by a director. If the Company is for any reason whatsoever
                      not ready to make delivery within the time specified the
                      Company shall not be liable for any loss or damage
                      whatsoever sustained by the Purchaser.{" "}
                    </p>
                    <p className="text-muted aboutus-text">
                      <strong>15.</strong> The Goods may be delivered by the Company before the
                      due delivery date upon giving reasonable notice to the
                      Purchaser. The Company reserves the right to deliver up to
                      ten per cent more or less than the quantity ordered
                      without any adjustment in the unit price and the quantity
                      so delivered shall be deemed to be the quantity ordered.
                    </p>
                    <p className="text-muted aboutus-text">
                      <strong>16.</strong> When it is necessary for the Purchaser to supply any
                      containers, packaging, labels, identifications or
                      particulars in respect of the Goods or do any other act to
                      enable the Company to effect delivery of the Goods the
                      same must be furnished or performed within such time as is
                      reasonably necessary to enable the Company to deliver the
                      Goods in accordance with the Contract.{" "}
                    </p>
                    <p className="text-muted aboutus-text">
                      <strong>17.</strong> Where the Goods are to be delivered by instalments,
                      each delivery shall constitute a separate contract and
                      failure by the Company to deliver any one or more of the
                      instalments in accordance with these Conditions or any
                      claim by the Purchaser in respect of any one or more
                      instalments shall not entitle the Purchaser to treat the
                      contract as a whole as repudiated.{" "}
                    </p>
                    <p className="text-muted aboutus-text">
                      <strong>18.</strong> If the Purchaser fails to take delivery of the Goods
                      or fails to give the Company adequate delivery
                      instructions at the time stated for delivery the Company
                      may (without prejudice to any other right or remedy
                      available to the Company):-
                      <ul className="list-unstyled ps-3">
                        <li>
                          <strong>18.1</strong> Store the Goods until actual delivery and charge
                          the Purchaser for the reasonable costs (including
                          insurance) of storage; or
                        </li>
                        <li>
                          <strong>18.2</strong> Sell the Goods at the best price readily
                          obtainable and (after deducting all reasonable storage
                          and selling expenses) account to the Purchaser for the
                          excess over the price under the contract or charge the
                          Purchaser for any shortfall below the price under the
                          contract.
                        </li>
                      </ul>
                    </p>
                    <h5>PAYMENT</h5>
                    <p className="text-muted aboutus-text">
                      <strong>19.</strong> The Company may request the Purchaser to deposit with
                      the Company a sum up to the equivalent of 50% of the
                      contract price with or in advance of order. If such sum is
                      not received in due time the Company shall cease to be
                      under a liability to supply the Goods in accordance with
                      the Contract. Any deposit received shall be placed in a
                      stakeholder account in the name of the Company. Any
                      interest earned thereon shall (subject to tax) accrue to
                      the Company.{" "}
                    </p>
                    <p className="text-muted aboutus-text">
                      <strong>20.</strong> Subject to any special terms agreed in writing between
                      the Company and the Purchaser, the Company may invoice the
                      Purchaser for the price of the Goods on or at any time
                      after the Goods have been made available for collection or
                      delivered to the Purchaser or to any third party on its
                      behalf. Where delivery of the Goods is extended over a
                      period of more than 1 day, the Company may request
                      progress payments in accordance with the amount of Goods
                      delivered and the Purchaser agrees to make such payments
                      on demand.{" "}
                    </p>
                    <p className="text-muted aboutus-text">
                      <strong>21.</strong> The Company may invoice for partial deliveries of
                      Goods if such partial delivery is deemed by the Company to
                      be necessary or expedient.{" "}
                    </p>
                    <p className="text-muted aboutus-text">
                      <strong>22.</strong> The Company does not trade on credit terms with any
                      Purchaser and receipt of cleared funds into the Company’s
                      bank account is required before collection or dispatch of
                      all or any Goods pursuant to a contract. Payment on the
                      due date of all sums due by the Purchaser to the Company
                      under any contract shall be of the essence of the
                      contract.{" "}
                    </p>
                    <p className="text-muted aboutus-text">
                      <strong>23.</strong> The Purchaser must pay with the order or otherwise
                      before delivery the price of Goods and, where appropriate,
                      this will be inclusive of carriage, duty and vat.{" "}
                    </p>
                    <p className="text-muted aboutus-text">
                      <strong>24.</strong> Where the Purchaser:-
                      <ul className="list-unstyled ps-3">
                        <li>
                          <strong>24.1</strong> is overdue with any payment owed to the Company;
                          or
                        </li>
                        <li>
                          <strong>24.2</strong> shall have failed to take delivery of the Goods;
                          or{" "}
                        </li>
                        <li>
                          <strong>24.3</strong> makes default in or commits any breach of its
                          other obligations to the Company hereunder; or{" "}
                        </li>
                        <li>
                          <strong>24.4</strong> becomes bankrupt insolvent or has a petition
                          presented in respect of an administration order or
                          winding-up order in respect of it or has a receiver
                          appointed of its assets or execution or distress
                          levied upon its assets or under the national law of
                          its own country suffers the equivalent of any of them,
                          or takes any step with a view to entering into a
                          voluntary arrangement with its creditors; or{" "}
                        </li>
                        <li>
                          <strong>24.5</strong> ceases or threatens to cease to trade, or if the
                          Company shall reasonably doubt the solvency of the
                          Purchaser; then (without prejudice to any other right
                          or remedy available to it) the Company reserves the
                          right to stop shipment, delivery or performance under
                          any contract and performance by the Company will only
                          recommence upon payment in full by the Purchaser of
                          all outstanding amounts due on due completion of the
                          Purchaser's obligations hereunder. The Company shall
                          not be liable for loss of any nature suffered by a
                          Purchaser as a result of the application of this
                          condition nor shall it be a reason for the
                          cancellation by the Purchaser of this or any other
                          contract which shall at the Company's option remain in
                          full force and effect.
                        </li>
                      </ul>
                    </p>
                    <p className="text-muted aboutus-text">
                      <strong>25.</strong> The Company may charge interest at a rate of 12 per
                      cent per annum above EIBOR on any overdue payments or in
                      respect of any delivery not taken by the Purchaser.{" "}
                    </p>
                    <p className="text-muted aboutus-text">
                      <strong>26.</strong> In the event that any of the conditions in Clause 24
                      do so arise, the Company shall be entitled without notice
                      to the Purchaser to resell all or any of such Goods in any
                      manner and to keep the proceeds in diminution of such
                      monies owed/due and of all costs and expenses incurred in
                      effecting such sale.{" "}
                    </p>
                    <p className="text-muted aboutus-text">
                      <strong>27.</strong> Payment shall be made at a nominated branch of the
                      Company's bankers in the UAE in Dirhams or at such other
                      place and in such other currency as the Company may
                      direct.{" "}
                    </p>
                    <h5>PROPERTY AND RISK IN GOODS </h5>
                    <p className="text-muted aboutus-text">
                      <strong>28.</strong> Below are the terms for property and risk in goods:
                      <ul className="list-unstyled ps-3">
                        <li>
                          <strong>28.1</strong> Risk in the Goods shall pass to the Purchaser
                          immediately on delivery to the Purchaser or into
                          custody on the Purchaser's behalf whichever is the
                          sooner.{" "}
                        </li>
                        <li>
                          <strong>28.2</strong> Notwithstanding delivery, the Goods shall remain
                          the absolute property of the Company (which reserves
                          the right to dispose of them) until the Company has
                          received the full price for the Goods and the full
                          price for any other Goods for which payment is due
                          from the Purchaser on or before payment of the price
                          of the Goods.{" "}
                        </li>
                        <li>
                          <strong>28.3</strong> Until property in the Goods passes to the
                          Purchaser the relationship between the Company and the
                          Purchaser shall be that of bailor and bailee and the
                          Purchaser shall store the Goods in such a way that
                          they are readily identifiable as the property of the
                          Company.{" "}
                        </li>
                        <li>
                          <strong>28.4</strong> If before the property in the Goods passes to the
                          Purchaser:-
                          <ul className="list-unstyled ps-3">
                            <li>
                              <strong>28.4.1</strong> the Goods are altered or other goods become
                              attached to the Goods or if any part of the Goods
                              is replaced or repackaged, such other goods or
                              replacement parts shall accede to and form part of
                              the Goods and such attachment and placement shall
                              not affect the Company's title as absolute owner
                              of the Goods;{" "}
                            </li>
                            <li>
                              <strong>28.4.2</strong> the Goods are sold by the Purchaser, such
                              sale or sales shall be deemed to be on behalf of
                              the Company, but without imposing any liability on
                              the Company to the sub-purchaser, and the
                              Purchaser shall hold such part of the proceeds of
                              sale or rights arising therefrom against the
                              sub-purchaser as represents the sum due to the
                              Company for such Goods as trustee for the Company
                              and the Purchaser shall keep such part of the
                              proceeds of such sale separate from its other
                              monies and account to the Company accordingly.{" "}
                            </li>
                          </ul>
                        </li>
                        <li>
                          <strong>28.5</strong> If payment of the price of the Goods or any part
                          of it is overdue or if it appears to the Company that
                          the Purchaser is or may be insolvent and be unable to
                          fulfil all it’s contractual obligations, the Company
                          may require the Purchaser to deliver up Goods
                          previously sold to the Company and, if the Purchaser
                          fails to do so forthwith, the Purchaser shall permit
                          the Company to recover and resell such Goods and by
                          its servants or agents enter upon the Purchaser's
                          premises (or such other the premises where such Goods
                          are stored or situated) for that purpose.{" "}
                        </li>
                        <li>
                          <strong>28.6</strong> The Purchaser shall not be entitled to pledge or
                          in any way charge by way of security for any
                          indebtedness any of the Goods which remain the
                          property of the Company but if the Purchaser does so
                          or purports to do so, all monies owing by the
                          Purchaser to the Company shall (without prejudice to
                          any other right or remedy of the Company) forthwith
                          become due and payable.{" "}
                        </li>
                      </ul>
                    </p>
                    <h5>DAMAGE IN TRANSIT </h5>
                    <p className="text-muted aboutus-text">
                      <strong>29.</strong> Where freight and or carriage has been arranged by the
                      Company, the Company will only accept liability for:-
                      <ul className="list-unstyled ps-3">
                        <li>
                          <strong>29.1</strong> damage to the Goods caused in transit if the same
                          is externally visible and is notified to the Company
                          and the carrier (if not delivered by the Company)
                          within three days of receipt of Goods by the
                          Purchaser;{" "}
                        </li>
                        <li>
                          <strong>29.2</strong> damage to the Goods caused in transit which is
                          not externally visible or any actual or apparent
                          discrepancy between any delivery note and items
                          delivered, if the same is notified to the Company and
                          the carrier (if not delivered by the Company) within
                          three days of receipt of the Goods by the Purchaser;{" "}
                        </li>
                        <li>
                          <strong>29.3</strong> non arrival, if the same is notified to the
                          Company within a reasonable period from the day that
                          the Company informed the Purchaser that the Goods or
                          the relevant consignment thereof were due to arrive.{" "}
                        </li>
                      </ul>
                      Where the Company accepts responsibility under this
                      clause, it may, at its sole option, repair or replace (as
                      the case may be) those of the Goods which are proved to
                      the Company’s satisfaction to have been lost or damaged
                      prior to delivery to the Purchaser.
                    </p>
                    <p className="text-muted aboutus-text">
                      <strong>30.</strong> The Company shall not be under any obligation to
                      replace (although it may do so at its option) Goods
                      destroyed damaged sunk in transit or detained by the
                      relevant Authorities or (in the case where Goods are
                      obtained by the Company from factories overseas) for the
                      total or partial suspension of deliveries which may be
                      occasioned by any circumstances attributable to war
                      revolution or civil unrest, nor shall the Company be
                      responsible for any damage caused to the Purchaser by the
                      non-delivery of such Goods.
                    </p>
                    <h5>FORCE MAJEURE </h5>
                    <p className="text-muted aboutus-text">
                      <strong>31.</strong> The Company shall be relieved of its obligations under
                      any contract to the extent to which the fulfilment of such
                      obligations is prevented, frustrated or impeded as a
                      consequence wholly or partially by any cause beyond the
                      Company's control including Act of God, war, invasion, act
                      of foreign enemy, hostilities (whether war has been
                      declared or not), civil war, rebellion, revolution,
                      insurrection or military or usurped power or any such
                      event or by any statute rules regulations orders or
                      requisitions issued by any government, council or duly
                      constituted authority or from strikes, lock outs or
                      breakdown of plant, or pandemic, endemic or other such
                      pubic health events.{" "}
                    </p>
                    <h5>SMALL PACKING CASES </h5>
                    <p className="text-muted aboutus-text">
                      <strong>32.</strong> Small packages are dispatched by post, carrier,
                      courier, plane or train at consignees expense.{" "}
                    </p>
                    <h5>GUARANTEE </h5>
                    <p className="text-muted aboutus-text">
                      <strong>33.</strong> The Goods shall correspond in all material respects
                      with the prototype produced by the Company and according
                      to Specifications or general descriptions on the Company’s
                      website and so notified to the Buyer.{" "}
                    </p>
                    <p className="text-muted aboutus-text">
                      If any Goods supplied by the Company do not so conform,
                      subject to any tolerances stated by the Company EITHER{" "}
                    </p>
                    <p className="text-muted aboutus-text">
                      the Company shall free of charge repair or replace the
                      defective Goods and shall deliver the repaired or
                      replacement Goods to the original point of delivery (but
                      otherwise in accordance with and subject to these
                      conditions of sale).
                    </p>
                    <p className="text-muted aboutus-text">OR </p>
                    <p className="text-muted aboutus-text">
                      the Company may at its absolute discretion either refund
                      or give credit for the price of the defective portion of
                      the Goods. In any dispute as to whether Goods are in
                      conformity to Specifications, the decision of SGS, or
                      equivalent independent body, pursuant to a Goods audit to
                      2.5AQL shall be final.
                    </p>
                    <p className="text-muted aboutus-text">
                      <strong>34.</strong> The Company accepts no responsibility where materials
                      supplied by the Purchaser or through its offices or from
                      sources dictated by the Purchaser shall be defective or in
                      any unsatisfactory condition and the Purchaser shall
                      indemnify the Company against any loss suffered by the
                      Company as a result of such defects or condition.{" "}
                    </p>
                    <h5>INDEMNITY </h5>
                    <p className="text-muted aboutus-text">
                      <strong>35.</strong> The Purchaser shall forthwith indemnify the Company on
                      a full indemnity basis against all or any liability cost
                      or expense of whatsoever nature incurred by the Company
                      due to an alleged or actual infringement of any
                      intellectual property right or otherwise arising out of
                      Goods manufactured or services provided by the Company to
                      the Purchaser's order and in accordance with any samples,
                      designs, specifications and/or instructions given by the
                      Purchaser to the Company.{" "}
                    </p>
                    <h5>SUB-CONTRACT LOGISTICS </h5>
                    <p className="text-muted aboutus-text">
                      <strong>36.</strong> The Company shall be entitled to sub-contract all or
                      any of its warehousing, logistics and distribution
                      obligations hereunder.{" "}
                    </p>
                    <h5>DRAWINGS ETC. </h5>
                    <p className="text-muted aboutus-text">
                      <strong>37.</strong> All descriptive and specifications drawings and other
                      particulars included in the Company's website or submitted
                      hereto are approximate only. The descriptions and
                      illustrations contained in the Company's website,
                      catalogues, price list and other documentation are
                      intended merely to present a general idea of the Goods
                      described therein and none of these shall form part of a
                      contract. Drawings etc. and all details thereon are
                      private and confidential to the Company and all
                      intellectual property in the Goods and the drawings etc.
                      vest in and belong to the Company and subject at all times
                      to the overriding rights in any such intellectual property
                      vested in any third party. Drawings etc. are supplied
                      under the express condition that they are not reproduced
                      nor communicated to any other person in whole or part nor
                      may the information contained therein be used directly or
                      indirectly in any way detrimental to the interests of the
                      Company without the written consent of the Company.{" "}
                    </p>
                    <h5>CONFIDENTIALITY AND NON-CIRCUMVENTION </h5>
                    <p className="text-muted aboutus-text">
                      <strong>38.</strong> During business negotiations and disclosure of
                      certifications and other commercial and or regulatory
                      matters, the Company may disclose certain Confidential
                      Information about the manufacturing party of the Goods,
                      including their name, address, employee contacts etc.. The
                      Purchaser agrees that they, and all of their affiliated
                      companies, agents, employees, successors in interest or
                      assigns, will not pursuant to this contract and for a
                      three (3) year period after this contract fulfilment,
                      engage in Circumvention Conduct without the prior written
                      consent of the Company, which consent the Company may
                      withhold in their sole discretion. <br />“Circumvention Conduct”
                      means (a) attempting to deal directly or indirectly in any
                      manner with any of the contact persons or other
                      individuals or companies related to the manufacture and supply of the
                    Goods, or <br/>(b) by-pass, avoid, circumvent, or attempt to
                    circumvent the Company relative to the purchase, sourcing or
                    supply of the Goods, including by utilizing any of the
                    Confidential Information or by otherwise exploiting or
                    deriving any benefit from the Confidential Information</p>
                    <h5>SUBSTITUTION AND SEVERANCE </h5>
                    <p className="text-muted aboutus-text">
                      <strong>39.</strong> Any condition hereof that is hereafter found invalid
                      or unenforceable in whole or in part for any reason shall
                      whenever allowed by the context be deemed replaced by such
                      valid and enforceable clause, covenant or provision (if
                      any) whose contents are as close as permissible to those
                      of the invalid or unenforceable clause, covenant or
                      provision. If such replacement is not possible, the
                      offending condition or part thereof shall be severed
                      herefrom and such severance shall not otherwise affect or
                      be deemed to affect the remainder (if any) of such
                      condition or otherwise of any remaining clauses of these
                      terms and conditions of sale.{" "}
                    </p>
                    <h5>WAIVER </h5>
                    <p className="text-muted aboutus-text">
                      <strong>40.</strong> Any waiver, forbearance or failure by the Company in
                      insisting in any one or more incidences upon the
                      performance of these conditions shall not be construed as
                      a waiver or relinquishment of the Company's right to
                      future performance of such condition and the Purchaser's
                      obligations in respect of such future performance shall
                      continue in full force and effect.{" "}
                    </p>
                    <h5>NO SET OFF </h5>
                    <p className="text-muted aboutus-text">
                      <strong>41.</strong> Any payment to be made by the Purchaser to the Company
                      shall be made in full without any set off or deduction
                      therefrom or any counterclaim or claim to a lien thereover
                      howsoever the same may arise.{" "}
                    </p>
                    <h5>LAW OF CONTRACT </h5>
                    <p className="text-muted aboutus-text">
                      <strong>42.</strong> These conditions and any contract hereunder shall be
                      governed by and construed according to the laws and
                      regulations of the United Arab Emirates as applied in the
                      Emirate of Dubai. And the Company and the Purchaser submit
                      to the exclusive jurisdiction of the Dubai Courts in
                      respect of any dispute claim or matter arising under such
                      a contract or in regard to these conditions.{" "}
                    </p>
                    <h5>NOTICE </h5>
                    <p className="text-muted aboutus-text">
                      <strong>43.</strong> Any notice or document required or permitted to be
                      given to or served on one party hereto by another party
                      shall be in writing in English and shall be given or
                      served by delivering or dispatching the same by one of the
                      methods set out below to its registered office if a
                      company and (if not) to the party's last known address.
                      Provided that where necessary the dispatch of such notice
                      or document has been properly pre-paid a notice or
                      document so given or served shall conclusively be deemed
                      to have been received at the time set out alongside the
                      respective manner of service namely:-
                      <ul className="list-unstyled ps-3">
                        <li>
                          <strong>43.1</strong> by hand on the recipient or an authorised officer
                          thereof - at the time of such service;
                        </li>
                        <li>
                          <strong>43.2</strong> by post within UAE - at the commencement of the
                          first business day next commencing more than 48 hours
                          after dispatch;
                        </li>
                        <li>
                          <strong>43.3</strong> by telex telecopier facsimile transmission or
                          other electronic means of written communication - at
                          the commencement of the first business day next
                          commencing more than 48 hours after dispatch;
                        </li>
                        <li>
                          <strong>43.4</strong> abroad by airmail post - at the commencement of
                          the first business day next commencing more than 96
                          hours after dispatch.
                        </li>
                      </ul>
                    </p>
                    <h5>GENERAL PROVISIONS </h5>
                    <p className="text-muted aboutus-text">
                      <strong>44.</strong> Here are the general provisions:
                      <ul className="list-unstyled ps-3">
                        <li>
                          <strong>44.1</strong> Information exchanged between the Parties in the
                          lead up to signing of this contract and contained
                          within this contract will only be used by the
                          recipient for the purposes of fulfilling this
                          contract. Such information will not be disclosed to
                          any third party in any manner, without the prior
                          written consent of the other Party, unless the
                          disclosure is required by law. Each Party will
                          establish and maintain such safeguards as are
                          necessary and appropriate to protect the
                          confidentiality of information received from the other
                          Party to this contract. And the Purchaser expressly
                          covenants not to use any confidential information to
                          seek to procure or to procure the Goods or
                          substantially similar Goods from the Company’s
                          suppliers.{" "}
                        </li>
                        <li>
                          <strong>44.2</strong> Unless the Purchaser has additional contracts
                          with the Company, this contract represents the entire
                          agreements between the Parties and supersedes all
                          prior discussions, negotiations and verbal contracts
                          between them.{" "}
                        </li>
                        <li>
                          <strong>44.3</strong> This contract does not create an agency,
                          partnership, joint venture, employment or franchise
                          relationship between the Parties. The Purchaser
                          expressly acknowledges that, unless agreed in writing
                          by the Company and signed by a Director, the Purchaser
                          does not have any exclusivity in respect to Goods
                          purchased.{" "}
                        </li>
                        <li>
                          <strong>44.4</strong> The benefits of this contract may not be assigned
                          by the Purchaser in whole or in part without the prior
                          written consent of the Company.{" "}
                        </li>
                        <li>
                          <strong>44.5</strong> This contract is made for the benefit of the
                          Parties and may be enforced by either Party. The
                          contract may not be amended orally, but only by a
                          written instrument signed by a Director of each Party.{" "}
                        </li>
                        <li>
                          <strong>44.6</strong> Each Party warrants and represents to the other
                          Party that he has, and until fulfillment of this
                          contract is complete, will continue to have full legal
                          capacity, right, power and authority to enter into,
                          execute and perform their respective obligations under
                          this contract.
                        </li>
                      </ul>
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default PageConditions;

import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { useStore } from "../context/store";
import { observer } from "mobx-react-lite";

import Slider from "react-slick";

const Section =() => {
  const {slideStore}  = useStore();
  const [items, setItems] = useState([])

  useEffect(()=>{
    async function LoadSlides() {
      const slides = await slideStore.loadSlideList();
      setItems(slides)
    }
    LoadSlides() 
    
  },[]);
  
  var settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 3500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    draggable: true,
    pauseOnHover: false,
  };
    return (
      <React.Fragment>
        <section className="home-slider position-relative"
        >
          <Slider className="carousel slide carousel-fade" {...settings}>
            {items && items.map((item, key) => (
              <>
              <li
                key={key + 1}
                className={"bg-home slider-rtl-2 d-flex align-items-center " + item.class}
                style={{ background: `url(${item.image_url}) center center` }}
              >
                <Container>
                  <Row className="align-items-center mt-5">
                    <Col lg={7} md={7} className="slider-desc">
                      <div className="title-heading mt-4">
                        <h1 className="display-4 title-white fw-bold mb-3">
                          {item.main_text} <br /> {item.titleLine2}
                        </h1>
                        <p className={"para-desc text-muted para-dark "+ item.textClass}>
                          {item.sub_text}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </li>
              </>
            ))}
          </Slider>
        </section>
      </React.Fragment>
    );
}
export default observer(Section);

import React from 'react';
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import large from '../../assets/images/rrhealth/gallery-3_c16288aa-6a65-47c4-ad08-f940522d95ad_480x480@2x.jpg';
import Img1 from "../../assets/images/rrhealth/gallery-1_8341e523-8f95-466a-b9c4-42d5cff28f1f_480x480@2x.jpg";
import Img2 from "../../assets/images/rrhealth/gallery-2_3ec179c9-361e-44a5-a26e-20ab6cab331f_480x480@2x.jpg";
import Img3 from "../../assets/images/rrhealth/gallery-4_9bc000ae-75bf-4951-9aef-5045039c09ea_480x480@2x.jpg";
import Img4 from "../../assets/images/rrhealth/gallery-5.jpg";
import './SharedComponents.css';

const MasonryBanner = () => {
    return (
        <React.Fragment>
            <Container fluid>
                <div className="rounded pt-5 masonary-banner">
                    <Row>
                        <Col xs={12} md={12} lg={6}>
                            <Row>
                                <Col xs={12} md={6}>
                                    <Card key={1} className="border-0 work-container work-modern position-relative d-block overflow-hidden">
                                        <CardBody className="p-0">
                                            <img src={Img1} className="img-fluid highlightsImg"  alt="work" />
                                            <div className="overlay-work bg-dark"></div>
                                            <div className="content">
                                                <h3 className="text-light tag mb-0">Disposable Medical Face Mask</h3>
                                                <h4 className="text-light tag mb-0">Protect Yourself</h4>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Card key={2} className="border-0 work-container work-modern position-relative d-block overflow-hidden">
                                        <CardBody className="p-0">
                                            <img src={Img2} className="img-fluid" alt="work" />
                                            <div className="overlay-work bg-dark r-0"></div>
                                            <div className="content">
                                                <h3 className="text-light tag mb-0">Pro-Manus SURE TOUCH</h3>
                                                <h4 className="text-light tag mb-0">Mid-weight for long lasting <br/> comfort and protection</h4>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Card key={3} className="border-0 work-container work-modern position-relative d-block overflow-hidden">
                                        <CardBody className="p-0">
                                            <img src={Img4} className="img-fluid" alt="work" />
                                            <div className="overlay-work bg-dark"></div>
                                            <div className="content">
                                                <h2 className="text-light tag mb-0">Pro-Manus SOFT TOUCH</h2>
                                                <h3 className="text-light tag mb-0">Fine-weight for extra sensitivity and feel</h3>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Card key={4} className="border-0 work-container work-modern position-relative d-block overflow-hidden">
                                        <CardBody className="p-0">
                                            <img src={Img3} className="img-fluid highlightsImg" alt="work" />
                                            <div className="overlay-work bg-dark r-0"></div>
                                            <div className="content">
                                                <h3 className="text-light tag mb-0">Pro-Manus TOUGH TOUCH</h3>
                                                <h4 className="text-light tag mb-0">Heavier-weight for durable versatility and strength</h4>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={12} lg={6}>
                            <Card key={5} className="border-0 work-container work-modern position-relative d-block overflow-hidden">
                                <CardBody className="p-0 large-image">
                                    <img src={large} className="img-fluid" alt="work" />
                                    <div className="overlay-work bg-dark l-0"></div>
                                    <div className="content">
                                        <h3 className="text-light tag mb-0">Disposable Surgical Masks</h3>
                                        <h4 className="text-light tag mb-0">For maximum Protection</h4>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Container>
        </React.Fragment>
    )
}

export default MasonryBanner;
import { createContext, useContext } from "react";
import BlogStore from "./BlogStore";
import SliderStore from "./SliderStore";
import ProductStore from "./ProductStore";
import CategoryStore from "./CategoryStore";

export const store = {
    blogStore: new BlogStore(),
    slideStore: new SliderStore(),
    productStore: new ProductStore(),
    categoryStore: new CategoryStore()
}

export const StoreContext = createContext(store);

export const StoreProvider = (props) => {
    return (
        <StoreContext.Provider value={store}>
            {props.children}
        </StoreContext.Provider>
    )
}

export function useStore() {
    return useContext(StoreContext);
}


import React, {useEffect}  from "react";
import { observer } from "mobx-react-lite";
import Section from "./Section";
import Features from "./Features";
import Highlights from "./Highlights";
import MasonryBanner from "../components/Shared/MasonryBanner";
import BlogBox from "../components/Shared/BlogBox";
import { useStore } from "../context/store";

const Home = () => {
  const {categoryStore} = useStore();
  
  useEffect(() => {
    categoryStore.loadCategoryList();
    
  }, [])

    return (
      <React.Fragment>
        <Section />
        <Features />
        <MasonryBanner />
        <Highlights />
        <BlogBox />
      </React.Fragment>
    );
  
}

export default observer(Home);

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { FaHome } from 'react-icons/fa';
import img404 from "../assets/images/404.svg";

class PageError extends Component {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  goBack() {
    window.history.back();
  }

  render() {
    return (
      <React.Fragment>
        <div className="back-to-home rounded d-none d-sm-block">
          <Link to="/" className="btn btn-icon btn-soft-primary">
            <i>
              <FaHome />
            </i>
          </Link>
        </div>

        <section className="bg-home d-flex align-items-center">
          <Container>
            <Row className="justify-content-center">
              <Col lg={8} md={12} className="text-center">
                <img src={img404} className="img-fluid page-not-found-img" alt="pagenotfound" />
                <div className="text-uppercase display-3">Oh ! no</div>
                <div className="text-capitalize text-dark mb-2 error-page">
                  Page Not Found
                </div>
                <p className="text-muted para-desc mx-auto">
                  This is not the web page you are looking for. For more information please contact support info@rr-healthcare.com
                </p>
              </Col>
            </Row>

            <Row>
              <Col md="12" className="text-center">
                <Link
                  to="#"
                  onClick={this.goBack}
                  className="btn btn-outline-primary mt-3"
                >
                  Go Back
                </Link>{" "}
                <Link to="/" className="btn btn-primary mt-3 ms-2">
                  Go To Home
                </Link>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
export default PageError;
import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Row,
  Col,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import classnames from "classnames";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { observer } from "mobx-react-lite";
import { useStore } from "../context/store";

//Import Slick Slider
import Slider from "react-slick";

import FeatherIcon from "feather-icons-react";

import PageBreadcrumb from "../components/Shared/PageBreadcrumb";

function ShopProductDetail() {

  const { location } = useHistory();

  const {productStore} = useStore();
  const [selectedId, setSelectedId] = useState(location.state?.params);
  const [modal, setModal] = useState(false);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [items, setItems] = useState(1);
  const [activeTab, setActivetab] = useState("1");
  const [messageVisible, setMessageVisible] = useState(false);
  const [data, setData] = useState({
    full_name: "",
    email: "",
    subject:"",
    message: ""
  })
  const [loading, setLoading] = useState(false)
  const [emailError, setEmailError] = useState("");

  useEffect(()=>{
     productStore.loadProductList();
     setSelectedId(productStore.stateId)
  },[productStore.stateId])
   
  const filteredProduct = productStore.productList && productStore.productList.filter(pd => pd._id ===selectedId);

  const formEl = useRef(null)

  const togglemodal = () => {
    setModal(!modal);
  };

  function toggle(tab) {
    setActivetab(tab);
  }

  var settings = {
    autoplay: false,
    infinite: items.length > 3,
    autoplaySpeed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    draggable: true,
    pauseOnHover: true,
  };

  const settings2 = {
    dots: false,
    infinite: items.length > 3,
    autoplaySpeed: 2000,
    autoplay: true,
    slidesToShow: filteredProduct[0] && filteredProduct[0].images_url.length,
    slidesToScroll: 1,
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if(!formEl.current._inputs.email.context.FormCtrl.hasError()){
      setLoading(true)
    }
    const response = await productStore.sendEmail(data);
    if (response === "success") {
      setLoading(false)
      setMessageVisible(true)
    }
    else if(response !== "success"){
      setLoading(false)
      setEmailError("Something Went Wrong!")
    }
    
  };

  const handleChange = (e) => {
    const {name, value} = e.target;
    setData({...data, [name]: value})
  }
  const [dataError, setDataError] = useState("Loading...")

  const ErrorStatus = () => {

    setTimeout(() => {
      setDataError("Data Not Available")
    }, 7000)    

    return <h2 className="text-center">{dataError}</h2>
  }
  return (
    <React.Fragment>
      <PageBreadcrumb title="Product Details" />
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <section className="section pb-0 product-detail-page">
        <Container>
          {filteredProduct.length !== 0 ?  filteredProduct.map((p, index) => (
            <Row className="align-items-center" key={index + 1}>
              <Col md={5}>
                <Slider
                  {...settings}
                  asNavFor={nav2}
                  ref={(slider1) => setNav1(slider1)}
                >
                  {p.images_url && p.images_url !==null && p.images_url.map((image,index) =>(
                    <div key={index + 1}>
                    <img
                      src={image}
                      className="img-fluid rounded big-image"
                      alt="product"
                    />
                  </div>
                   )) }
                </Slider>

                <Slider
                  asNavFor={nav1}
                  {...settings2}
                  ref={(slider2) => setNav2(slider2)}
                  // slidesToShow={5}
                  swipeToSlide={true}
                  focusOnSelect={true}
                >
                  {p.images_url && p.images_url !==null && p.images_url.map((image,index) =>(
                  <div key={index + 1}>
                    <img src={image} className="img-fluid thumbnail-image" alt="thumbnail" />
                  </div>
                  ))}
                </Slider>
              </Col>

              <Col md={7} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div className="section-title ms-md-4">
                  <h4 className="title">{p.name}</h4>
                  <h5 className="mt-4 py-2">Overview :</h5>
                  <div className="text-muted"
                        dangerouslySetInnerHTML={{__html: p.overview}}
                      />
                  
                  <Row className="mt-4 pt-2">
                    <Col lg={7} xs={12}>
                      <div className="d-flex align-items-center">
                      {(p.size_info[0].ref_no === '' && p.size_info[1].ref_no === '' && p.size_info[2].ref_no === '' && p.size_info[3].ref_no === '' && p.size_info[4].ref_no === '') ? 
                      null :
                        <h6 className="mb-0">Available Size:</h6>
                      }
                        {p.size_info && p.size_info.map((sz, index) => sz.size !=="" ? (
                          
                        <ul className="list-unstyled mb-0 ms-3" key={index + 10}>

                            <li className="list-inline-item sizes-text" key={index + 1}>
                              {sz.size}
                              &nbsp;
                            </li>
                          
                        </ul>): ""
                        )}
                      </div>
                    </Col>
                  </Row>

                  <div className="mt-4 pt-2">
                    <Link
                      to="#"
                      className="btn btn-primary"
                      onClick={togglemodal}
                    >
                      Inquire
                    </Link>{" "}
                  </div>
                  <Modal
                    tabIndex="-1"
                    centered
                    isOpen={modal}
                    toggle={togglemodal}
                    contentClassName="rounded shadow border-0"
                  >
                    {!messageVisible && (
                    <ModalHeader toggle={togglemodal}>
                      Please fill up the form and we will contact you.
                    </ModalHeader>
                    )}
                    <ModalBody className="p-4">
                    <div id="message">{emailError}</div>
                    {!messageVisible ? (
                      <> 
                      {loading === true ? (
                        <div class="d-flex justify-content-center m-5">
                          <div class="spinner-border text-info" role="status">
                            <span class="sr-only"></span>
                          </div>
                        </div>
                      ) : (
                      <AvForm onSubmit={handleSubmit} ref={formEl}>
                        <Row>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Your Name <span className="text-danger">*</span>
                              </Label>
                              <div className="form-icon position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="user"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <AvField
                                name="full_name"
                                id="full_name"
                                type="text"
                                className="form-control ps-5"
                                placeholder="Your Name :"
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Please enter your full name",
                                  },
                                }}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Your Email{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <div className="form-icon position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="mail"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <AvField
                                name="email"
                                id="email"
                                type="email"
                                className="form-control ps-5"
                                placeholder="Your email :"
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Please enter email",
                                  },
                                }}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Confirm Email{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <div className="form-icon position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="mail"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <AvField
                                name="confirmEmail"
                                id="confirmEmail"
                                type="email"
                                className="form-control ps-5"
                                placeholder="Your email :"
                                required
                                errorMessage="Email ID does not match"
                                validate={{ match: { value: "email" } }}
                              />
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Subject<span className="text-danger">*</span>
                              </Label>
                              <div className="form-icon position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="book"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <AvField
                                name="subject"
                                id="subject"
                                className="form-control ps-5"
                                placeholder="Your subject :"
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Please enter subject",
                                  },
                                }}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Message <span className="text-danger">*</span>
                              </Label>
                              <div className="form-icon position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="message-circle"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <AvField
                                name="message"
                                type="textarea"
                                id="mailmessage"
                                rows="4"
                                className="form-control ps-5"
                                placeholder="Your Message :"
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Please enter message",
                                  },
                                }}
                                required
                                onChange={(e) => handleChange(e)}
                              ></AvField>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12}>
                            <Button
                              id="submit"
                              name="send"
                              className="btn btn-primary"
                              value="Send Request"
                            >
                              Send Request
                            </Button>
                          </Col>
                        </Row>
                      </AvForm>
                      )}
                      </>
                      ) : (
                        <>
                          <div className="text-center">
                            <h3>Your message has been sent and we will get in touch soon.</h3>
                            <Button type="button" className=" btn btn-primary mt-3" onClick={() =>{
                              togglemodal();
                            }}>
                              Close
                            </Button>
                          </div>
                        </>
                      )}

                    </ModalBody>
                  </Modal>
                </div>
              </Col>
            </Row>
          )): ErrorStatus()}
        </Container>

        <Container className="mt-100 mt-60">
          <Row>
            <Col xs={12}>
              <ul className="nav nav-pills flex-column flex-sm-row d-md-inline-flex  bg-white rounded position-relative overflow-hidden">
                <NavItem className="m-0">
                  <NavLink
                    to="#"
                    className={classnames(
                      { active: activeTab === "1" },
                      "rounded py-1 px-3"
                    )}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    <div className="text-center">
                      <h6 className="mb-0">Description</h6>
                    </div>
                  </NavLink>
                </NavItem>

                <NavItem className="m-0">
                  <NavLink
                    to="#"
                    className={classnames(
                      { active: activeTab === "2" },
                      "rounded py-1 px-3"
                    )}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    <div className="text-center">
                      <h6 className="mb-0">Sizes Info</h6>
                    </div>
                  </NavLink>
                </NavItem>

                <NavItem className="m-0">
                  <NavLink
                    to="#"
                    className={classnames(
                      { active: activeTab === "3" },
                      "rounded py-1 px-3"
                    )}
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    <div className="text-center">
                      <h6 className="mb-0">Technical Specs</h6>
                    </div>
                  </NavLink>
                </NavItem>
                <NavItem className="m-0">
                  <NavLink
                    to="#"
                    className={classnames(
                      { active: activeTab === "4" },
                      "rounded py-1 px-3"
                    )}
                    onClick={() => {
                      toggle("4");
                    }}
                  >
                    <div className="text-center">
                      <h6 className="mb-0">Certificates</h6>
                    </div>
                  </NavLink>
                </NavItem>
              </ul>
              {filteredProduct.length !== 0 && filteredProduct.map((p, index) => (
                <TabContent className="mt-4" activeTab={activeTab} key={index}>
                  <TabPane className="card border-0 fade show" tabId="1"  key={index + 1}>
                    <div className="text-muted description"
                        dangerouslySetInnerHTML={{__html: p.description}}
                      />
                  </TabPane>
                  <TabPane className="card border-0 fade show" tabId="2" key={index + 2}>
                     <div className="text-muted ">
                     <ul className="list-unstyled mb-0 ms-3">
                       <table className="table table-sm px-0 size-info-table">
                        <thead>
                          {(p.size_info[0].ref_no === '' && p.size_info[1].ref_no === '' && p.size_info[2].ref_no === '' && p.size_info[3].ref_no === '' && p.size_info[4].ref_no === '') ?
                            null :
                          <tr>
                          <th>Size</th>
                          <th>Reference No.</th>
                        </tr> 
                          }
                        </thead>
                        <tbody>
                        {p.size_info && p.size_info.map((sz, index) => ( sz.size !== "" ?
                            <tr key={index + 1}> 
                                <td>{sz.size}</td> 
                                <td>{sz.ref_no}</td>
                            </tr> 
                            : ""
                          ))}
                        </tbody>
                        </table>
                          
                        </ul>
                     </div>
                  </TabPane>

                  <TabPane className="card border-0 fade show" tabId="3" key={index + 3}>
                      <div className="text-muted table-responsive techspec-table"
                        dangerouslySetInnerHTML={{__html: p.tech_specs}}
                      />
                  </TabPane>

                  <TabPane className="card border-0 fade show" tabId="4" key={index + 4}>
                      <div className="text-muted"
                        dangerouslySetInnerHTML={{__html: p.certification}}
                      />
                  </TabPane>
                </TabContent>
              ))}
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default observer(ShopProductDetail);

function list_to_tree(list) {
    var map = {}, node, roots = [], i;
    
    for (i = 0; i < list.length; i += 1) {
      map[list[i].id] = i; // initialize the map
      list[i].nestedChild = []; // initialize the children
    }
    
    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      if (node.parent !== null) {
        // if you have dangling branches check that map[node.parentId] exists
        list[map[node.parent]].nestedChild.push(node);
      } else {
        roots.push(node);
      }
    }
    return roots;
}

export default list_to_tree;
// React Basic and Bootstrap
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container } from "reactstrap";

//Import Icons
import FeatherIcon from "feather-icons-react";

// import images
import contactDetail from "../assets/images/rrhealth/contact-detail.jpg";
import PageHeader from "../components/Shared/pageHeader";
// import Topbar from "../components/Layout/Topnav";

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.sendMail.bind(this);
    this.callNumber.bind(this);
    this.state = {
      pathItems: [
        //id must required
        { id: 1, name: "RR Healthcare", link: "/" },
        { id: 3, name: "Contact Us" },
      ],
    };
  }

  sendMail() {
    window.location.href = "mailto:info@rr-healthcare.com";
  }

  callNumber() {
    window.location.href = "tel:+152534-468-854";
  }

  render() {
    return (
      <React.Fragment>
        {/* <Topbar hasDarkTopBar={true} /> */}
        {/* breadcrumb */}
        <PageHeader title="Contact Us" pathItems={this.state.pathItems} />

        <section
          className="vh-100 d-flex align-items-center"
          style={{
            background: `center / contain no-repeat url(${contactDetail}) `,
          }}
        >
          {/* <div className="bg-overlay bg-overlay-white"></div> */}
          <Container>
            <Row className="align-items-center">
              {/* <Col lg={{ size: 6, offset: 6 }} md={{ size: 7, offset: 5 }}> */}
              <Col lg={{ size: 6 }} md={{ size: 7 }}>
                <div className="title-heading mt-5 pt-4">
                  <h1 className="heading">
                    Let's talk about your requirements
                  </h1>
                  <p className="text-dark">
                    PPE kit | Online Personal Protective Equipment in Dubai, UAE
                  </p>

                  <div className="d-flex contact-detail align-items-center mt-3">
                    <div className="icon">
                      <i>
                        <FeatherIcon
                          icon="mail"
                          className="fea icon-m-md text-dark me-3"
                        />
                      </i>
                    </div>
                    <div className="flex-1 content">
                      <h5 className="title fw-bold mb-0">Email</h5>
                      <Link
                        to="#"
                        onClick={this.sendMail}
                        className="text-primary"
                      >
                        info@rr-healthcare.com
                      </Link>
                    </div>
                  </div>

                  <div className="d-flex contact-detail align-items-center mt-3">
                    <div className="icon">
                      <i>
                        <FeatherIcon
                          icon="map-pin"
                          className="fea icon-m-md text-dark me-3"
                        />
                      </i>
                    </div>
                    <div className="flex-1 content">
                      <h5 className="title fw-bold mb-0">Location</h5>
                      <Link to={{pathname: "https://goo.gl/maps/Fyn4shk8MaxdFBtA8"} } target="_blank" className="video-play-icon text-primary">
                        View on Google map
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
export default ContactUs;

import axios from 'axios/index';

axios.defaults.baseURL = "https://api.rr-healthcare.com/api/v1";

const products = {
    get:() => {
        return axios.get('/product/all')
    },
}

const categories = {
    get:() => {
        return axios.get('/category/all')
    },
}
const blogs = {
    get:() => {
        return axios.get('/blog/all')
    },
}
const slides = {
    get:() => {
        return axios.get('/slider/all')
    },
}

const email = {
    post:(data) =>{
        return axios.post('/sendmail', data)
    }
} 
const agent = {
    products,
    blogs,
    slides,
    categories,
    email
}


export default agent;
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/service";


export default class BlogStore{
    blogList = [] ;

    get getBlogs() {        
        return this.blogList 
    }
    getBlog(id) {        
        return this.blogList && this.blogList.filter(blog => blog.id === id)
    }
    
    constructor(){
        makeAutoObservable(this)
    }
    loadBlogList = async () => {
        try {
            const responsedata = await agent.blogs.get()
            if(responsedata.data.message === 'Success') {
                runInAction(() => {
                    this.blogList = responsedata.data.data
                });  
                return responsedata.data.data;
            }      
        }
        catch (error) {
            console.log("error", error.response ? error.response : error)
            throw error
        }
    }
}


import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useStore } from "../context/store";
import { observer } from "mobx-react-lite";
import { Card, CardBody, CardImg, Col, Container, Row } from "reactstrap";
import PageHeader from "../components/Shared/pageHeader";

function AllBlog() {
  const {blogStore}  = useStore();
  const [blogs, setBlogs] = useState([])

  const pathItems = [
    { id: 1, name: "RR Healthcare", link: "/" },
    { id: 3, name: "News" },
  ];  
    
  useEffect(()=>{
    async function getBlocks(){
      const blogs = await blogStore.loadBlogList();
      setBlogs(blogs)
    }
    getBlocks()
  },[])

  return (
    <React.Fragment>
      <PageHeader title="Recent News" pathItems={pathItems} />

      <Container className="mt-100 mt-60">
        <Row className="justify-content-center">
            <Col xs={12}>
              <div className="section-title text-center mb-4 pb-2">                            
                <h4 className="title mt-3 mb-4">{"Recent News"}</h4>                            
              </div>
            </Col>
        </Row>
        <Row className="row">
          {blogs && blogs.map((blog, key) => (
            <Col lg="4" md="6" className="mt-4 pt-2" key={key} name="blog">
              <Card className="blog rounded border-0 shadow">
                <div className="position-relative">
                  <CardImg top src={blog.image_url} className="rounded-top imgHeight" alt="" />
                  <div className="overlay rounded-top bg-dark"></div>
                </div>
                <CardBody className="content">
                  <h5 className="blogTtl">
                    {blog.is_link === true ? (
                    <Link to={{pathname: blog.link} } target="_blank" className="card-title title text-dark overflow-hidden">
                      {blog.title}
                    </Link>
                    ): (
                      <Link to={`/blogdetail/${blog.id}`} className="card-title title text-dark overflow-hidden">
                        {blog.title}
                      </Link>
                    )}
                  </h5>
                  <div className="post-meta d-flex justify-content-between mt-3">
                    <Link to={{pathname: blog.link}} target="_blank" className="text-muted readmore">
                      Read More <i className="mdi mdi-chevron-right"></i>
                    </Link>
                  </div>
                </CardBody>
                <div className="author">
                  <small className="text-light user d-block">
                    <i className="mdi mdi-account"></i> {blog.author}
                  </small>
                  <small className="text-light date">
                    <i className="mdi mdi-calendar-check"></i> {blog.publish_date.slice(0,10)}
                  </small>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default observer(AllBlog)
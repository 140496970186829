import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/service";

export default class SliderStore{
    slideList = [] ;

    get getSlides() {        
        return this.slideList && this.slideList
    }
    
    constructor(){
        makeAutoObservable(this)
    }
    
    loadSlideList = async () => {
        
        try {
            const data = await agent.slides.get()
            if(data.data.message === "Success") {
                runInAction(() => {
                    this.sliderList = data.data.data
                }); 
                return data.data.data;             
            }  
        }
        catch (error) {
            console.log("GetBlogListError", error)
            throw error
        }
    }
    
}
import { Link } from "react-router-dom";
import logodark from "../../assets/images/rrhealth/rr-healthcare_logo-250.png";
import './Layout.css';

export default function Footer() {
    return (
            <>
              <footer className="footer bg-dark mt-5 pb-3">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-xs-12 ">
                      <Link to="/" className="logo-footer">
                        <img src={logodark} width="132"  alt="" />
                      </Link>
                    </div>
                    <div className="col-lg-3 col-md-3 col-xs-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                      <h5 className="text-light footer-head"> 
                        Company 
                      </h5>
                      <ul className="list-unstyled footer-list mt-4">                    
                        <li>
                          <Link to='/aboutus' className="text-muted">
                            <i className="mdi mdi-chevron-right me-1"></i>{" "}
                            About Us
                          </Link>
                        </li> 
                        <li>
                          <Link to='/contactus' className="text-muted">
                            <i className="mdi mdi-chevron-right me-1"></i>{" "}
                            Contact Us
                          </Link>
                        </li> 
                        <li>
                          <Link to='/news' className="text-muted">
                            <i className="mdi mdi-chevron-right me-1"></i>{" "}
                            News
                          </Link>
                        </li> 
                      </ul>
                    </div>
                    <div className="col-lg-3 col-md-3 col-xs-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                      <h5 className="text-light footer-head">
                        Useful Links
                      </h5>
                      <ul className="list-unstyled footer-list mt-4">
                        <li>
                          <Link to='/termsofservices' className="text-muted">
                            <i className="mdi mdi-chevron-right me-1"></i>
                            Terms of Services
                          </Link>
                        </li>
                        <li>
                          <Link to='/privacypolicy' className="text-muted">
                            <i className="mdi mdi-chevron-right me-1"></i>
                            Privacy Policy
                          </Link>
                        </li> 
                        <li>
                          <Link to='/conditions' className="text-muted">
                            <i className="mdi mdi-chevron-right me-1"></i>
                            Terms & Conditions of Sale
                          </Link>
                        </li> 
                      </ul>
                    </div>
                    <div className="col-lg-12 pt-5">                    
                        <p className="text-center">RR HealthCare ©2021 Created by <a href="https://web55.tech" target="_blank">Web55 Technologies</a></p>              
                    </div>
                  </div>
                </div>
              </footer>
            </>
    )
}

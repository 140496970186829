import React, { useEffect } from "react";
import { Link,useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import { observer } from "mobx-react-lite";
import { useStore } from "../context/store";
import FeatherIcon from "feather-icons-react";
import PageHeader from "./pageHeader";

 const PageBlogDetailTwo = () => {

  const {blogStore} = useStore();
  const { id } = useParams();
  useEffect(()=>{
    blogStore.loadBlogList();
  }, [])
  const blogPost = blogStore.getBlog(id)
    
     const pathItems = [
        //id must required
        { id: 1, name: "RR Healthcare", link: "/" },
        { id: 3, name: "News" },
      ]
    
  
   
    return (
      <React.Fragment>
        <PageHeader title="Recent News" pathItems={pathItems} />
        <section className="section">
          <Container>
            <Row className="justify-content-center">
              <Col lg={10}>
                <Row>
                  <Col md={2} className="d-none d-md-block">
                    <ul className="list-unstyled text-center sticky-bar social-icon mb-0">
                      <li className="mb-3 h6">Share</li>
                      <li>
                        <Link to="#" className="rounded">
                          <FeatherIcon
                            icon="facebook"
                            className="fea icon-sm fea-social"
                          />
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="rounded">
                          <FeatherIcon
                            icon="instagram"
                            className="fea icon-sm fea-social"
                          />
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="rounded">
                          <FeatherIcon
                            icon="twitter"
                            className="fea icon-sm fea-social"
                          />
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="rounded">
                          <FeatherIcon
                            icon="linkedin"
                            className="fea icon-sm fea-social"
                          />
                        </Link>
                      </li>
                    </ul>
                  </Col>

                    {blogPost.map(post => (
                  <div className="col-md-10">
                      <h3 >{post.title}   </h3>
  
                      <ul className="list-unstyled d-flex justify-content-between mt-4">
                        <li className="list-inline-item user me-2">
                          <Link to="#" className="text-muted">
                            <i className="uil uil-user text-dark"></i>{post.author}
                          </Link>
                        </li>
                        <li className="list-inline-item date text-muted">
                          <i className="uil uil-calendar-alt text-dark"></i>{" "}
                          {post.publish_date}
                        </li>
                      </ul>
  
                      <img
                        src={post.image}
                        className="img-fluid rounded-md shadow"
                        alt=""
                      />

                       <div className="text-muted"
                        dangerouslySetInnerHTML={{__html: post.body}}
                      />
                    </div>
                    ))}
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  
}
export default observer(PageBlogDetailTwo)
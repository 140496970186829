// React Basic and Bootstrap
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody
} from "reactstrap";

//Import components
import PageBreadcrumb from "../components/Shared/PageBreadcrumb";

const PageTerms =()=> {
const pathItems =  [
        //id must required
        { id: 1, name: "RR Healthcare", link: "/" },
        { id: 4, name: "Terms of services" },
      ]

  
useEffect(() => {
  window.addEventListener("scroll", scrollNavigation, true);
  return () => {
    window.removeEventListener("scroll", scrollNavigation, true);
  }
}, [])
 

 const scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      document.getElementById("topnav").classList.add("nav-sticky");
    } else {
      document.getElementById("topnav").classList.remove("nav-sticky");
    }
  };

    return (
      <React.Fragment>
        <PageBreadcrumb
          title="Terms of Services"
          pathItems={pathItems}
        />
        <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>

        <section className="section">
          <Container>
            <Row className="justify-content-center">
              <Col lg={9}>
                <Card className="shadow border-0 rounded">
                  <CardBody>
                    <div>
                      <p className="text-muted aboutus-text">
                        Please read this Terms of Service ("Terms," "Terms of
                        Service") carefully before using the
                        www.rr-healtchare.com Website (the "Website")
                        operated by Chinese & Southeast Asian Products FZCO a
                        Limited Liability Freezone Company formed in Dubai,
                        United Arab Emirates ("us," "we," "our") as this Terms
                        of Service contains important information regarding
                        limitations of our liability. Your access to and use of
                        this Website is conditional upon your acceptance of and
                        compliance with these Terms. These Terms apply to
                        everyone, including but not limited to visitors, users
                        and others, who wish to access and use the Website.
                      </p>
                      <p className="text-muted aboutus-text">
                        By accessing or using the Website, you agree to be bound
                        by these Terms. If you disagree with any part of the
                        Terms, then you do not have our permission to access or
                        use the Website.
                      </p>
                    </div>
                    <h4>OUR PRODUCTS AND PRICING</h4>
                    <p className="text-muted aboutus-text">
                      We display our products on this Website and invite
                      inquiries and offers. Prices for products are negotiated
                      contract by contract depending on the specific
                      requirements of each customer.
                    </p>
                    <h4>SHIPPING POLICY</h4>
                    <p className="text-muted aboutus-text">
                      When you purchase our goods, either we or you will arrange
                      a third party to ship these goods to you. Where a third
                      party is used for shipping, the shipping will be governed
                      by that third party’s Terms of Service and other
                      applicable policies.
                    </p>
                    <h4>TERMS AND CONDITIONS OF SALE</h4>
                    <p className="text-muted aboutus-text">
                      Our standard Terms and Conditions of Sale are accessed via
                      this link <Link to="/conditions">T&Cs</Link>. All sales
                      are concluded on this basis only unless otherwise agreed
                      and confirmed in writing signed and stamped by a director
                      prior to the sale.
                    </p>
                    <h4>PROHIBITED USES</h4>
                    <p className="text-muted aboutus-text">
                      You agree that you will use this Website in accordance
                      with all applicable laws, rules, regulations and these
                      Terms at all times. The following is a non-exhaustive list
                      of prohibited uses of this Website. You agree that you
                      will not perform any of the following prohibited uses:
                    </p>
                    <p className="text-muted aboutus-text">
                      Impersonating or attempting to impersonate Pro-Manus /
                      rr-healthcare.com / Chinese & Southeast Asian Products
                      FZCO or its employees;
                    </p>
                    <p className="text-muted aboutus-text">
                      Misrepresenting your identity or affiliation with any
                      person or entity;
                    </p>
                    <p className="text-muted aboutus-text">
                      Sending or attempting to send any advertising or
                      promotional material, including but not limited to spam,
                      junk mail, chain mail or any similar material;
                    </p>
                    <p className="text-muted aboutus-text">
                      Engaging in any conduct that restricts or inhibits any
                      person’s use or enjoyment of the Website, or which, as
                      determined in our sole discretion, may harm us or the
                      users of this Website or expose us or other users to
                      liability;
                    </p>
                    <p className="text-muted aboutus-text">
                      Using the Website in any manner that could disable,
                      overburden, damage or impair the Website or interfere with
                      another party’s use of the Website;
                    </p>
                    <p className="text-muted aboutus-text">
                      Using any robot, spider or other similar automatic
                      technology, process or means to access or use the Website
                      for any purpose, including monitoring or copying any of
                      the material on this Website;
                    </p>
                    <p className="text-muted aboutus-text">
                      Using any manual process or means to monitor or copy any
                      of the material on this Website or for any other
                      unauthorized purpose;
                    </p>
                    <p className="text-muted aboutus-text">
                      Using any device, software, means or routine that
                      interferes with the proper working of the Website,
                      including but not limited to viruses, trojan horses,
                      worms, logic bombs or other such materials;
                    </p>
                    <p className="text-muted aboutus-text">
                      Attempting to gain unauthorized access to, interfering
                      with, damaging or disrupting any parts of the Website, the
                      server(s) on which the Website is stored, or any server,
                      computer or database connected to the Website;
                    </p>
                    <p className="text-muted aboutus-text">
                      Attempting to attack or attacking the Website via a
                      denial-of-service attack or a distributed
                      denial-of-service attack;
                    </p>
                    <p className="text-muted aboutus-text">
                      Otherwise attempting to interfere with the proper working
                      of the Website;
                    </p>
                    <p className="text-muted aboutus-text">
                      Using the Website in any way that violates any applicable
                      federal, state or local laws, rules or regulations.
                    </p>
                    <h4>NO WARRANTY ON WEBSITE</h4>
                    <p className="text-muted aboutus-text">
                      THIS WEBSITE IS PROVIDED “AS IS,” NO WARRANTY, EXPRESS OR
                      IMPLIED (INCLUDING ANY IMPLIED WARRANTY OF
                      MERCHANTABILITY, OF SATISFACTORY QUALITY OR FITNESS FOR A
                      PARTICULAR PURPOSE OR USE) SHALL APPLY TO THIS WEBSITE,
                      WHETHER ARISING BY LAW, COURSE OF DEALING, COURSE OF
                      PERFORMANCE, USAGE OF TRADE OR OTHERWISE.
                    </p>
                    <h4>AVAILABILITY, ERRORS AND INACCURACIES</h4>
                    <p className="text-muted aboutus-text">
                      We assume no liability for the availability, errors or
                      inaccuracies of the information, products or services
                      provided on this Website. We may experience delays in
                      updating information on this Website and in our
                      advertising on other websites. The information, products
                      and services found on the Website may contain errors or
                      inaccuracies or may not be complete or current. Products
                      may be unavailable. We expressly reserve the right to
                      correct any errors on our Website. The inclusion or
                      offering of any third party product or service on this
                      Website does not constitute an endorsement or
                      recommendation of such product or service by us.
                    </p>
                    <h4>DAMAGES AND LIMITATION OF LIABILITY</h4>
                    <p className="text-muted aboutus-text">
                      IN NO EVENT SHALL CHINESE & SOUTHEAST ASIAN PRODUCTS FZCO
                      BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL,
                      SPECIAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF, RELATING
                      TO OR IN ANY WAY CONNECTED WITH YOUR ACCESS TO, DISPLAY OF
                      OR USE OF THIS WEBSITE OR WITH THE DELAY OR INABILITY TO
                      ACCESS, DISPLAY OR USE THIS WEBSITE, INCLUDING BUT NOT
                      LIMITED TO YOUR RELIANCE UPON OPINIONS OR INFORMATION
                      APPEARING ON THIS WEBSITE; ANY COMPUTER VIRUSES,
                      INFORMATION, SOFTWARE, LINKED WEBSITES OPERATED BY THIRD
                      PARTIES, PRODUCTS OR SERVICES OBTAINED THROUGH THIS
                      WEBSITE, WHETHER BASED ON A THEORY OF NEGLIGENCE,
                      CONTRACT, TORT, STRICT LIABILITY, CONSUMER PROTECTION
                      STATUTES OR OTHERWISE, EVEN IF CHINESE & SOUTHEAST ASIAN
                      PRODUCTS FZCO HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                      DAMAGES. THIS LIMITATION OF LIABILITY REFLECTS THE
                      ALLOCATION OF RISK BETWEEN YOU AND US. THE LIMITATIONS
                      SPECIFIED IN THIS SECTION WILL SURVIVE AND APPLY EVEN IF
                      ANY LIMITED REMEDY SPECIFIED IN THESE TERMS OF USE IS
                      FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. THE
                      LIMITATIONS OF LIABILITY PROVIDED IN THESE TERMS OF USE
                      INURE TO THE BENEFIT OF CHINESE & SOUTHEAST ASIAN PRODUCTS
                      FZCO. THE AGGREGATE LIABILITY OF CHINESE & SOUTHEAST ASIAN
                      PRODUCTS FZCO ARISING OUT OF OR RELATING TO THIS WEBSITE,
                      WHETHER ARISING OUT OF OR RELATED TO BREACH OF CONTRACT,
                      TORT (INCLUDING NEGLIGENCE) OR OTHERWISE SHALL BE LIMITED
                      TO THE AMOUNT OF FEES ACTUALLY RECEIVED BY CHINESE &
                      SOUTHEAST ASIAN PRODUCTS FZCO FROM YOU.
                    </p>
                    <h4>LINKS TO THIRD PARTY WEBSITES</h4>
                    <p className="text-muted aboutus-text">
                      This Website may contain hyperlinks to websites operated
                      by third parties and not by us. We provide such hyperlinks
                      for your reference only. We do not control such websites
                      and are not responsible for their contents or the privacy
                      or other practices of such websites. Further, it is your
                      responsibility to take precautions to ensure that whatever
                      links you click on or software that you download, whether
                      from this Website or other websites or applications, is
                      free of such items as viruses, worms, trojan horses,
                      defects and other items of a destructive nature. Our
                      inclusion of hyperlinks to such websites does not imply
                      any endorsement of the material on such websites or any
                      association with their operators.
                    </p>
                    <h4>INTELLECTUAL PROPERTY</h4>
                    <p className="text-muted aboutus-text">
                      The intellectual property and copyright in this website is
                      owned by Chinese and Southeast Asian Products FZCO which
                      also owns the Trademark for the Pro-Manus brand.
                    </p>
                    <h4>
                      GOVERNING LAW, SEVERABILITY, DISPUTE RESOLUTION AND VENUE
                    </h4>
                    <p className="text-muted aboutus-text">
                      These Terms shall be governed by and construed according
                      to the laws and regulations of the United Arab Emirates as
                      applied in the Emirate of Dubai. The Company and the User
                      submit to the exclusive jurisdiction of the Dubai Courts
                      in respect of any dispute claim or matter arising under
                      such a contract or in regard to these conditions. THESE
                      TERMS SHALL NOT BE GOVERNED BY THE UNITED NATIONS
                      CONVENTION ON CONTRACTS FOR THE SALE OF INTERNATIONAL
                      GOODS, THE UNIFORM COMMERCIAL CODE, NOR INCOTERMS. Our
                      failure to enforce any right or provision of these Terms
                      will not be considered a waiver of that right or
                      provision. If any provision of these Terms is held to be
                      invalid or unenforceable by a Court, the remaining
                      provisions of these Terms will remain in effect. These
                      Terms constitute the entire agreement between you and us
                      regarding our Website and supersede and replace any prior
                      agreements we might have had with you regarding the
                      Website. YOU AND CHINESE & SOUTHEST ASIAN PRODUCTS FZCO
                      AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN
                      YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR
                      CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION.
                    </p>
                    <h4>CHANGES TO TERMS OF SERVICE</h4>
                    <p className="text-muted aboutus-text">
                      We reserve the right to make changes to these Terms of
                      Service at any time. We will notify you immediately of
                      making any changes to these Terms of Service via by
                      posting the updated terms of service to this website.
                    </p>
                    <h4>QUESTIONS</h4>
                    <p className="text-muted aboutus-text">
                      If you have any questions about our Terms of Service,
                      please contact us at <a href="mailto:info@rr-healthcare.com">info@rr-healthcare.com</a>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }

  export default PageTerms;

import { makeAutoObservable, runInAction } from "mobx";
import { makePersistable } from 'mobx-persist-store';
import agent from "../api/service";

export default class ProductStore {
  productList = [];
  stateId= "";

  setStateId(id) {
    this.stateId = id;
  }

  getProduct(id) {
    return this.products && this.products.filter((p) => p._id === id);
  }
  constructor() {
    makeAutoObservable(this);
    makePersistable(this, { name: 'ProductStore', properties: ['stateId'], storage: window.localStorage });
  }

  loadProductList = async () => {
    const response = await agent.products
      .get()
      .then((response) => {
        if (response.data.message === "Success") {
          runInAction(() => {
            this.productList = response.data.data;
          });
          return response.data.data;
        }
      })
      .catch((error) => {
        console.log("error", error.response ? error.response : error);
      });
  };

  sendEmail = async (data) => {
    const response = await agent.email.post(data);
    try {
      if (response.data.message === "success") {
        return "success";
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
}

import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import ctaImg from "../assets/images/rrhealth/highlights.jpg";

class Cta extends Component {
    render() {
        return (
            <React.Fragment>
                <Container fluid >
                    <div
                        className="rounded py-5"
                        style={{ background: `url(${ctaImg}) fixed center` }}
                    >
                        <Container>
                            <Row className="justify-content-center">
                                <div className="section-title text-center mb-4 pb-2">
                                    <h2 className="title mt-3 pt-3 mb-4 text-capitalize">See Why Customers Love Our Products</h2>
                                    <h3 className="text-uppercase">Designed to protect</h3>
                                </div>
                            </Row>
                            <Row>
                                <Col lg={5} md={6} xs={12}>

                                </Col>

                                <Col lg={7} md={6} xs={12} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                                    <div className="accordion" id="accordionExample">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingOne">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    <h4>Innovative Design</h4>
                                                </button>
                                            </h2>
                                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                Designed with thickness specific for your duties. So regardless of whether you are a doctor, nurse, dentist, care-giver, beautician, food-line worker, cleaner or industrial worker, we have the glove to help and protect you. 
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    <h4>Fully Certified</h4>
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                Our products are fully CE and FDA certified to the highest standards for your peace of mind.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                <h4>High Quality Product</h4>
                                                </button>
                                            </h2>
                                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                Our products are manufactured strictly in accordance with ISO9001:2015 and ISO13485:2016 Quality Management Systems and tested to AQL1.5 standards.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingFour">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                <h4>Customer Service</h4>
                                                </button>
                                            </h2>
                                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                Our order process is simple and we deliver consistently on time. Within the UAE we hold stock for next day delivery.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingFour">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                <h4>Outstanding Value</h4>
                                                </button>
                                            </h2>
                                            <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                 Our direct to factory e-commerce model means that our customers get a superior and trusted product for a great, great price.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

export default Cta;

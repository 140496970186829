import "./Apps.scss";
import "./App.css";
import "./assets/css/colors/default.css";
import Topnav from "./components/Layout/Topnav";
import {
  Route,
  Switch,
  BrowserRouter as Router,
} from "react-router-dom";
import Home from "./pages/Home";
import Footer from "./components/Layout/Footer";
import ContactUs from "./pages/ContactUs";
import AllBlog from "./pages/AllBlog"
import ShopProductDetail from "./pages/ShopProductDetail";
import PageBlogDetailTwo from "./pages/PageBlogDetailTwo";
import ScrollToTop from "./components/Shared/ScrollToTop";
import PageAboutUs from "./pages/PageAboutUs";
import PageError from "./pages/PageNotFound";
import PagePrivacy from "./pages/PagePrivacy";
import PageTerms from "./pages/PageTerms";
import PageConditions from "./pages/PageConditions";


function App() {
  return (
    <>
    <Router>
    <ScrollToTop />
    <Topnav/>
    <Switch>
      <Route exact path="/">
      <Home />
      </Route>
      <Route path="/contactus">
        <ContactUs/>
      </Route>
      <Route path="/product-detail/:id">
        <ShopProductDetail/>
      </Route>
      <Route path="/aboutus">
        <PageAboutUs/>
      </Route>
      <Route path="/news">
        <AllBlog/>
      </Route>
      <Route path="/termsofservices">
        <PageTerms/>
      </Route>
      <Route path="/privacypolicy">
        <PagePrivacy/>
      </Route>
      <Route path="/conditions">
        <PageConditions/>
      </Route>
      <Route path="/blogdetail/:id">
        <PageBlogDetailTwo/>
      </Route>
      <Route path="/*">
        <PageError/>
      </Route>
    </Switch>
    <Footer/>
    </Router>
      
    </>
  );
}

export default App;

import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/service";

class CategoryStore{
    categories = []

    constructor(){
        makeAutoObservable(this)
    }

    loadCategoryList = async () => {
            const data = await agent.categories.get()
            .then(response => {                          
                if(response.data.message === 'Success'){
                    runInAction(() => {                          
                        this.categories = response.data.data
                    });
                }
            })
            .catch(error => {
                console.log("error", error.response ? error.response : error)
            })
    } 

}

export default CategoryStore;